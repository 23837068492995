import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';

import how_1 from './img/how-it-works-1.png';
import how_2 from './img/how-it-works-2.png';
import how_3 from './img/how-it-works-3.png';

const HowItWorks = () =>
  <div className="">

    <Helmet title="Call Flow | SwitchSmith">
    <link rel="canonical" href="https://switchsmith.com/how" />
    </Helmet>

    <div className="row pt3">
        <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
        <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
        <h1>Call Flow</h1>

            <p>
              This scenario illustrates how a group of four colleagues handle an incoming customer call:
            </p>

            <div>
              <ol>
                <li>The customer calls the group number</li>
                <li>SwitchSmith alerts all group members either by push notification or to their desk phone</li>
              </ol>
            </div>

            <div className="pa3">
              <img src={how_1}/>
            </div>

            <div>
              <ol start="3">
                <li>The customer hears a greeting and music on hold</li>
                <li>A member of the group is free to talk and chooses to accept the inbound call</li>
                <li>SwitchSmith calls the the chosen group member's phone</li>
              </ol>
            </div>

            <div className="pa3">
              <img src={how_2}/>
            </div>

            <div>
              <ol start="6">
                <li>The group member answers the call and is now talking with the customer</li>
              </ol>
            </div>

            <div className="pa3">
              <img src={how_3}/>
            </div>
        </div>
      </div>
  </div>


const HowPage = () => (
  <Layout>
    <HowItWorks/>
  </Layout>
)

export default HowPage;